<template>
  <router-view />
</template>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: rgb(242, 244, 245);
}

a {
  color: rgb(77, 78, 78);
  line-height: 20px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 1500px;
  margin: 0 auto;
}

.wrapper {
  width: 95%;
  margin: 0 auto;
}

h2 {
  font-family: 'Times New Roman', Times, serif;
  text-align: left;
}
/* 
@media (min-width:15) {
  
} */
</style>
